<template>
  <div
    v-bind="$attrs"
    :class="`col-xs-${this.col_sm} col-sm-${this.col_sm} col-md-${this.col_md} col-lg-${this.col_lg} col-xl-${this.col_lg} pr-4`"
  >
    <div class="form-group">
      <label>
        {{ this.label }}
        <span v-if="$attrs.required" class="required">*</span>
        <i  v-if="this.info != ''"
            :title=this.info
            class="fas fa-question-circle ml-1">
        </i>
      </label>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    col_sm: {
      type: String,
      default: "12",
      required: false,
    },
    col_md: {
      type: String,
      default: "12",
      required: false,
    },
    col_lg: {
      type: String,
      default: "6",
      required: false,
    },
    label: {
      type: String,
      default: "",
      required: true,
    },
    info: {
      type: String,
      default: "",
      required: false,
    },
  },
};
</script>

<style></style>
