<template>
  <div>
    <div class="content-header px-0">
      <h1 class="title-I">Grupos cadastrados</h1>
    </div>

    <div class="form-inline col-12 p-0">
      <div class="pl-0 col-10 filterInput" v-if="isAdmin()">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control col-11 w-100"
            id="filterInput"
            placeholder="Buscar"
          />
        </b-input-group>
      </div>
      <router-link
        id="addgroup"
        v-can="['ADMIN']"
        title="Adicionar grupo"
        to="/group/add"
        class="btn btn-primary add-user col-2"
      >
        <span class="d-none d-md-block d-lg-block">Adicionar</span>
        <span class="d-block d-md-none d-lg-none">
          <i class="fas fa-plus-circle"></i>
        </span>
      </router-link>
    </div>

    <b-table
      responsive="sm"
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      show-empty
      sort-icon-left
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum grupo encontrado para essa busca"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      @context-changed="changeBsTableState"
      :busy="load"
      :fields="fields"
      :items="groups"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
        />
      </template>
      <template v-slot:cell(pbi)="data">
        <a
          v-if="data.item.pbi"
          id="goToPBI"
          href="/dashboard"
          class="mr-3"
          title="Ver Power BI"
        >
          <img src="@/assets/img/pbi.png" width="20px" />
        </a>
        <span
          style="color: red; padding: 4px; border-radius: 4px; font-size: 18px"
          v-else
          ><i class="fas fa-times"></i
        ></span>
      </template>
      <template v-slot:cell(actions)="data">
        <!-- gear icon  -->
        <a
          id="configgroup"
          @click="openConfig(data.item)"
          v-can="['ADMIN']"
          class="mr-3"
          title="Configurar Autenticação"
          style="cursor: pointer"
        >
          <i class="fas fa-unlock-keyhole"></i>
        </a>
        <a
          id="excluirgroup"
          @click="groupDelete = data.item"
          v-can="['ADMIN']"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#ModalDeleteGroup"
          title="Excluir"
        >
          <i class="far fa-trash-alt"></i>
        </a>
        <a
          id="editgroup"
          @click="editGroup(data.item)"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          class="mr-3"
          title="Editar"
          style="cursor: pointer"
        >
          <i class="fas fa-pen iconspace"></i>
        </a>
      </template>
    </b-table>

    <Modal
      id="ModalDeleteGroup"
      @click="groupDelete = data.item"
      title="Excluir Grupo"
      v-bind:msg="'Tem certeza que deseja excluir ' + groupDelete.name + '?'"
      button="Excluir"
      :method="deleteGroup"
    />

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import hasRole from "../../utils/hasRole";
export default {
  name: "ManagerGroup",
  data() {
    return {
      filter: null,
      perPage: 10,
      sortBy: "name",
      currentPage: 1,
      rows: 1,
      groupDelete: "",
      fields: [
        { key: "name", sortable: true, label: "Nome" },
        { key: "pbi", sortable: true, label: "Power BI" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("user/getGroups");
  },
  watch: {
    groups() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx) {
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "name";
          this.rows = this.groups.length;
          return;
        }
        if (ctx.filter !== "" && ctx.filter !== null) {
          this.filter = ctx.filter;
        }

        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.groups.length;
    },
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    isAdmin() {
      return hasRole(["ADMIN"]);
    },
    onFiltered(groups) {
      this.rows = groups.length;
    },
    deleteGroup() {
      event.preventDefault();
      this.$store.dispatch("user/deleteGroup", {
        group_name: this.groupDelete.name,
      });
    },
    editGroup(data) {
      this.$router.push({
        name: "group-edit",
        params: { groupName: data.name },
      });
    },
    openConfig(data) {
      this.$router.push({
        name: "group-config",
        params: { groupName: data.name },
      });
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    groups() {
      return this.$store.getters["user/groups"];
    },
  },
  components: {
    Modal,
  },
};
</script>
